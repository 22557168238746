body,
div,
p,
span,
a,
img,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
tbody,
tr,
td,
th {
  padding: 0;
  margin: 0;
}
ul,
li {
  list-style: none;
}
body {
  font-size: 12px;
  font-family: 'Arial', 'Microsoft YaHei', '微软雅黑', '黑体-简';
  color: #000000;
  min-width: 1226px;
}

html,body{
  scroll-behavior:smooth;
}