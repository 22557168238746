.main_news {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.m_n_title {
  padding-top: 87px;
  height: 200px;
  text-align: center;
  color: #000;
}

.m_n_t_line {
  width: 27px;
  border: 1px solid #333;
  margin: 15px auto;
}

.m_n_title p:nth-child(1) {
  font-size: 45px;
}

.m_n_title p:nth-child(3) {
  font-size: 30px;
}

.m_n_crumb {
  font-size: 14px;
  color: #333;
}

.m_n_content {
  display: flex;
  margin-top: 40px;
}

.m_n_menu {
  font-size: 16px;
}
.m_n_menu li {
  width: 85px;
  height: 50px;
  border-top: 1px solid #333;
  line-height: 50px;
  cursor: pointer;
}

.m_n_menu li:hover {
  color: #e85524;
}

.m_n_menu_active{
  color: #e85524;
}

.m_n_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 80px;
}

.m_n_list li {
  cursor: pointer;
  display: flex;
  padding-bottom: 26px;
  margin-bottom: 32px;
  transition: all 0.2s linear;
  border-bottom: 1px solid #ddd;
}

.m_n_list li:hover{
  box-shadow: 10px 10px 10px rgb(0 0 0 / 10%);
  transform: translate3d(0, -2px, 0);
}


.m_n_list > li > img {
  width: 120px;
  height: 120px;
}

.m_n_detail {
  position: relative;
  margin-left: 20px;
  color: #000;
  line-height: 3.5em;
  width: 900px;
}

.m_n_detail > p:nth-child(1) {
  font-size: 19px;
}

.m_n_detail > p:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  opacity: 0.54;
  overflow: hidden;
}

.m_n_detail > p:nth-child(3) {
  width: 66px;
  height: 20px;
  padding: 7px;
  line-height: 20px;
  font-size: 14px;
  opacity: 0.54;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  text-align: center;
}

.m_n_detail > p:nth-child(4){
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 15px;
  color: #000;
  opacity: 0.54;
}
