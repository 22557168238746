.home_section {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.home {
  position: relative;
  touch-action: none;
  transition: all 700ms ease 0s;
}

.body {
  overflow: hidden;
}
