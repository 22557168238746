.product_detail {
  width: 100%;
  height: 100%;
}

.product_detail img {
  width: 100%;
}

.p_d_crumb {
  font-size: 14px;
  color: #333;
  margin: 5%;
}
