.home_partner {
  position: relative;
  width: 100%;
  height: 100%;
}

.home_partner_main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1200px;
  height: 600px;
}
.hpc_title{
  height: 150px;
  font-size: 32px;
  color: #000;
  text-align: center;
  line-height: 1.5em;
}
.hpc_title p:nth-child(2){
  font-size: 16px;
  color: #969696;
}

.hpc_content{
  height: 450px;
  overflow: hidden;
}

.hpc_img_list{
  display: flex;
  flex-wrap: wrap;
}

.hpc_img_list li img{
  height: 150px;
  width: 300px;
  transition: all 0.2s linear;
}


.hpc_img_list li img:hover {
  transform: scale(1.1);
}