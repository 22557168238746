.product_content {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.product_header {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  line-height:30px;
  margin-top: 9%;
}
.p_t_chinese {
  font-size: 26px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}

.p_t_english {
  font-size: 14x;
  font-weight: 400;
  color: #666666;
  margin-left: 5px;
}

.product_nav {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 450px;
}
.product_nav li {
  width: 70px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.product_nav li:hover {
  background-color: #333;
  color: #fff;
}

.product_nav_active{
  background-color: #333;
  color: #fff;
}

.product_list {
  display: flex;
  width: 100%;
  height: 620px;
  margin-top: 5.5%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.p_l_item {
  margin-bottom: 30px;
  width: 370px;
  height: 50%;
  transition: all 0.2s linear;
}

.p_l_item:hover {
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  transform: translate3d(0, -2px, 0);
}

.p_l_i_img {
  width: 100%;
  height: 80%;
  overflow: hidden;
}

.p_l_i_img img{
  width: 100%;
  height: 100%;
}

.p_l_i_title {
  height: 20%;
  border: 1px solid #eeeeee;
  text-align: center;
  line-height: 3em;
  font-size: 18px;
  font-weight: 400;
  color: #696969;
}
