.home_contact_bg {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/footer.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.home_contact_main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1200px;
  height: 562px;
}

.home_contact_title {
  width: 100%;
  height: 104px;
  color: #ffffff;
}

.home_contact_title p:nth-child(1) {
  font-size: 32px;
}

.home_contact_title p:nth-child(2) {
  font-size: 16px;
}

.home_contact_content {
  display: flex;
  width: 100%;
  height: 458px;
}
.home_c_m_left {
  position: relative;
  width: 806px;
  height: 100%;
  background-color: #fff;
}

.hcml_content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 682px;
  height: 286px;
}

.hcml_r1 {
  display: flex;
  font-size: 22px;
  color: #2d2d2d;
}

.hcml_company {
  width: 440px;
  line-height: 1.5em;
}

.hcml_company p:nth-child(2) {
  font-size: 18px;
  color: #6b6b6b;
}

.hcml_tencent {
  line-height: 1.5em;
}

.hcml_code{
  width: 125px;
  height: 125px;
}
.hcml_code img{
  width: 100%;
  height: 100%;
}


.hcml_tencent p:nth-child(2) {
  font-size: 18px;
  color: #6b6b6b;
}

.home_c_m_l_r2 {
  display: flex;
  margin-top: 80px;
}
.hcml_info {
  width: 440px;
  font-size: 16px;
  color: #6b6b6b;
  line-height: 2em;
}
.home_c_m_right {
  width: 394px;
  background: #00AEDC;
  font-size: 25px;
  color: #fff;
  line-height: 1.5em;
}

.hcmr_content {
  margin-top: 73px;
  margin-left: 51px;
}
.hcmr_content li p:nth-child(1) {
  font-size: 18px;
}
.hcmr_content span {
  font-size: 18px;
}
.hmcr_line {
  width: 18px;
  border-top: 1px solid #fff;
  margin: 20px 0;
}
