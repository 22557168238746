.main_product {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.m_p_title {
  padding-top: 87px;
  height: 200px;
  text-align: center;
  color: #000;
}

.m_p_t_line {
  width: 27px;
  border: 1px solid #333;
  margin: 15px auto;
}

.m_p_title p:nth-child(1) {
  font-size: 45px;
}

.m_p_title p:nth-child(3) {
  font-size: 30px;
}

.m_p_crumb {
  font-size: 14px;
  color: #333;
}

.m_p_content {
  display: flex;
  margin-top: 40px;
}

.m_p_menu {
  font-size: 16px;
}
.m_p_menu li {
  width: 85px;
  height: 50px;
  border-top: 1px solid #333;
  line-height: 50px;
  cursor: pointer;
}

.m_p_menu li:hover {
  color: #e85524;
}
.m_p_menu_active {
  color: #e85524;
}

.m_p_list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 80px;
  width: 80%;
}

.m_p_list li {
  margin: 10px;
  transition: all 0.2s linear;
}

.m_p_list li:hover {
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  transform: translate3d(0, -2px, 0);
}

.m_p_list li img {
  width: 285px;
  /* height: 324px; */
}

.m_p_list li div {
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  color: #333;
  text-align: center;
}
