.pgna_bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20px;
  height: 120px;
  top: 50%;
  right: 17px;
  transform: translateY(-50px);
  cursor: pointer;
  z-index: 1001;
}

.pgna_bar li {
  width: 7px;
  height: 7px;
  border: 1px solid #00aedc;
  border-radius: 50%;
  margin: 5px auto;
}

.pgna_bar li:hover {
  transform: scale(1.5);
  transition: all 0.1s ease-in;
}

.pagi_nav_active {
  background-color: #00aedc;
}
