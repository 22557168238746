.news_content {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.news_header {
  height: 5%;
  position: relative;
  margin-top: 2%;
}

.news_title_p1 {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 36px;
  color: #000;
}

.news_title_p2 {
  position: absolute;
  top: 50px;
  right: 10px;
  font-size: 14px;
  color: #aaa;
}

.news_title_line {
  position: absolute;
  top: 75px;
  right: 15px;
  width: 30px;
  border: 1px solid #000;
}

.news_imgs_swiper {
  display: flex;
  margin-top: 5%;
  width: 100%;
  height: 30%;
  cursor: pointer;
}

.news_imgs_swiper li {
  width: 33.333%;
  overflow: hidden;
  transition: all 0.2s linear;
}

.news_imgs_swiper img {
  width: 100%;
}

.news_imgs_swiper li:hover {
  transform: scale(1.2);
}

.news_article {
  display: flex;
  margin-top: 4%;
  justify-content: space-between;
}

.news_article li {
  width: 30%;
  text-align: left;
}

.news_article li p {
  padding: 2%;
}

.news_art_title {
  font-size: 26px;
  color: #5b5e66;
}
.news_art_location {
  font-size: 14px;
  color: #5b5e66;
}

.news_art_text {
  font-size: 12px;
  color: #999;
  line-height: 2em;
  width: 360px;
  height: 140px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.news_more_btn {
  margin-top: 5%;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 137px;
  height: 38px;
  border: 1px solid #333;
  border-radius: 18px;
  text-align: center;
  line-height: 38px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.news_more_btn > a {
  color: #333;
  text-decoration: none;
}
