.home_banner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.home_b_content {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  transition: all 700ms ease 0s;
}

.home_b_content_item {
  float: left;
  height: 100%;
}

.home_b_video{
  width: 100%;
  height: 100%;
}


.hb_video_player{
  width: 50px;
  height: 50px;

}


.home_b_left {
  position: absolute;
  top: 50%;
  left: 80px;
  width: 0;
  height: 0;
  border: solid;
  border-color: transparent rgba(68, 192, 240, 0.5) transparent transparent;
  border-width: 22px 22px 22px 0;
  cursor: pointer;
  z-index: 2;
}

.home_b_left:hover {
  border-color: transparent rgba(68, 192, 240, 1) transparent transparent;
}

.home_b_right {
  position: absolute;
  top: 50%;
  right: 80px;
  width: 0;
  height: 0;
  border: solid;
  border-color: transparent transparent transparent rgba(68, 192, 240, 0.5);
  border-width: 22px 0 22px 22px;
  cursor: pointer;
  z-index: 2;
}

.home_b_right:hover {
  border-color: transparent transparent transparent rgba(68, 192, 240, 1);
}

.home_b_pagination {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1001;
}

.home_b_pagination li {
  width: 7px;
  height: 7px;
  border: 1px solid #00aedc;
  border-radius: 50%;
  margin: 5px;
}

.home_b_pagination li:hover {
  transform: scale(1.5);
  transition: all 0.1s ease-in;
}

.home_b_pagi_active {
  background-color: #00aedc;
}

.home_header {
  position: absolute;
  top: 12px;
  height: 28px;
  width: 100%;
  line-height: 28px;
  z-index: 3;
}
.home_header_logo {
  position: absolute;
  display: block;
  width: 171px;
  height: 28px;
  background: url(../../assets/img/logo.png) 100% no-repeat;
  background-size: contain;
  top: 3px;
  left: 10%;
}
.home_header_nav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 600px;
  right: 10%;
  font-size: 18px;
  cursor: pointer;
}

.home_header_nav li {
  width: 87px;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.home_nav_active {
  background-color: #8a8a8a;
  color: #000;
}

.home_header_nav li:hover {
  background-color: #8a8a8a;
  color: #000;
}
