.about_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.about_img_p {
  margin-right: 2%;
}

.about_img_one {
  position: relative;
  width: 45%;
  height: 100vh;
  overflow: hidden;
}


.a_i_o_p1 {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 36px;
  color: #000;
}

.a_i_o_p2 {
  position: absolute;
  top: 56px;
  right: 10px;
  font-size: 14px;
  color: #aaa;
}


.a_i_o_line {
  position: absolute;
  top: 80px;
  right: 25px;
  width: 30px;
  border: 1px solid #000;
}


.about_img_two {
  height: 45%;
  margin-top: 15%;
  overflow: hidden;
}

.about_img_three {
  height: 29%;
  margin-top: 5%;
  overflow: hidden;
}

.about_introduce {
  width: 451px;
  height: 359px;
  padding: 2%;
  margin-top: 5%;
}
.about_intd_title {
  text-align: center;
  font-size: 24px;
  font-family: SourceHanSansCN-Light;
  color: #333;
}

.about_intd_content {
  margin-top: 30px;
  font-size: 14px;
  text-indent: 2em;
  line-height: 2em;
  color: #333;
}
